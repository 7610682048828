/**
 * 液位动画
 */
export const waterLevel = (v,calculate) => {
    let cal = 100 - calculate
    if (cal < 0) {
        return
    }
    v.attr({
        levelRect2: {
            style: {
                transform: `translateY(${cal}px)`
            }
        }
    })
    return v
}