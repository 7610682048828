import insertCss from 'insert-css';
/**
 * 旋转动画
 */
export const imgRotateAnmi = (v,contents) => {
    let speed = null
    let customAnimations = v.store.data.data.graphical.customAnimations
    customAnimations.forEach(item=>{
        if(item.animation=='rotate'&&item.imgRotate) {
            let animationRate = item.imgRotate.animationRate
            if(animationRate.rateType=='manual') {
                if(animationRate.manualRate==0.1) speed = 60
                if(animationRate.manualRate==0.2) speed = 20
                if(animationRate.manualRate==0.5) speed = 5
                if(animationRate.manualRate==1) speed = 4
                if(animationRate.manualRate==1.5) speed = 3
                if(animationRate.manualRate==2) speed = 2
                if(animationRate.manualRate==3) speed = 1.5
                if(animationRate.manualRate==4) speed = 1
                if(animationRate.manualRate==5) speed = 0.5
            }
            if(animationRate.rateType=='auto') {
                if(contents) {
                    contents.forEach(con=>{
                        if(con.id==animationRate.relativeTag) {
                            let rateVal = Number(con.value) / Number(animationRate.relativeTagValue)
                            let rate = Number(rateVal.toFixed(2))
                            if(rate<0.1) speed = 60
                            if(0.1<=rate && rate<0.2) speed = 60
                            if(0.2<=rate && rate<0.5) speed = 20
                            if(0.5<=rate && rate<1) speed = 5
                            if(1<=rate && rate<1.5) speed = 4
                            if(1.5<=rate && rate<2) speed = 3
                            if(2<=rate && rate<3) speed = 2
                            if(3<=rate && rate<4) speed = 1.5
                            if(4<=rate && rate<5) speed = 1
                            if(5<=rate) speed = 0.5
                        }
                    })
                }
            }
        }
    })
    v.attr({
        image: {
            style: {
                'transform-box': 'fill-box',
                'transform-origin': 'center',
                animation: `animations${v.size().width} ${speed}s linear infinite normal`,
            }
        },
    })
    insertCss(`
        @keyframes animations${v.size().width} {
            from {
                transform: rotate(0deg);
            }
            to {
                transform: rotate(360deg);
            }
        }
    `)
    return v
}