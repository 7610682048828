/**
 * 屏幕适配
 */
// 默认缩放值
const scale = {
    width: "1",
    height: "1",
}
// 设计稿尺寸(px)
const baseWidth = 1920;
const baseHeight = 1080;
// 需要保持的比例（默认1.77778）
const baseProportion = parseFloat((baseWidth / baseHeight).toFixed(5));

export const calcRate = (configContainer) => {
    // 当前宽高比
    const currentRate = parseFloat((window.innerWidth / window.innerHeight).toFixed(5))
    if(currentRate > baseProportion) {
      // 表示更宽
      scale.width = ((window.innerHeight * baseProportion) / baseWidth).toFixed(5);
      scale.height = (window.innerHeight / baseHeight).toFixed(5);
      configContainer.forEach(v=>{
        v.style.transform = `scale(${scale.width},${scale.height})`;
      })
    }else {
      // 表示更高
      scale.height = ((window.innerWidth / baseProportion / baseHeight)).toFixed(5);
      scale.width = (window.innerWidth / baseWidth).toFixed(5);
      configContainer.forEach(v=>{
        v.style.transform = `scale(${scale.width},${scale.height})`;
      })
    }
}
