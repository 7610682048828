import insertCss from 'insert-css';
/**
 * 管道流水动画
 */
export const waterLinearAnmi = (v,contents) => {
    let speed = 60
    let customAnimations = v.store.data.data.graphical.customAnimations
    customAnimations.forEach(item=>{
        if(item.pipelineFlow.animationRate) {
            let animationRate = item.pipelineFlow.animationRate
            if(animationRate.rateType=='manual') {
                if(animationRate.manualRate==0.5) speed = 5
                if(animationRate.manualRate==1) speed = 4
                if(animationRate.manualRate==1.5) speed = 3
                if(animationRate.manualRate==2) speed = 2
                if(animationRate.manualRate==3) speed = 1.5
                if(animationRate.manualRate==4) speed = 1
                if(animationRate.manualRate==5) speed = 0.5
            }else if(animationRate.rateType=='auto') {
                if(contents) {
                    contents.forEach(con=>{
                        if(con.id==animationRate.relativeTag) {
                            let rateVal = Number(con.value) / Number(animationRate.relativeTagValue)
                            let rate = Number(rateVal.toFixed(2))
                            if(rate<0.5) speed = 5
                            if(0.5<=rate && rate<1) speed = 5
                            if(1<=rate && rate<1.5) speed = 4
                            if(1.5<=rate && rate<2) speed = 3
                            if(2<=rate && rate<3) speed = 2
                            if(3<=rate && rate<4) speed = 1.5
                            if(4<=rate && rate<5) speed = 1
                            if(5<=rate) speed = 0.5
                        }
                    })
                }
            }else {
                speed = v.size().width / speed
            }
        }else {
            if(v.size().width>0) speed = 2
            if(v.size().width>100) speed = 4
            if(v.size().width>200) speed = 6
            if(v.size().width>300) speed = 8
            if(v.size().width>400) speed = 10
            if(v.size().width>500) speed = 12
        }
    })
    v.attr({
    waterline1: {
        style: {
        animation: `animations${v.size().width} ${speed}s linear infinite normal`,
        }
    },
    waterline2: {
        style: {
        animation: `animations2 ${speed}s linear infinite normal`,
        }
    }
    })
    insertCss(`
    @keyframes animations${v.size().width} {
        to {
        transform: translate(${v.size().width+10}px, ${(v.size().height)/2}px);
        }
    }
    @keyframes animations2 {
        to {
        transform: translate(0px, ${(v.size().height)/2}px);
        }
    }
    `)
    return v
}