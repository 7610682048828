/**
 * 创建液位文字SVG标签
 */
export const createWaterSvg = (editableTabsValue, v, tag, val) => {
    v.attr('levelRect/class', 'water' + tag)
    // 通过class属性找到对应的元素标签
    const container = document.getElementById('containerDetailChart' + editableTabsValue)
    const tagContent = container.querySelector('.water' + tag)
    // 获取文本父节点
    let rectWidth = tagContent.getBoundingClientRect().width
    let rectHeight = tagContent.getBoundingClientRect().height
    // 创建svg和xhtml元素标签
    let svgNS = 'http://www.w3.org/2000/svg';
    let xhtml = 'http://www.w3.org/1999/xhtml';
    let foreignObject = document.createElementNS(svgNS, 'foreignObject')
    let body = document.createElementNS(xhtml, 'body')
    let p = document.createElement('p')
    let span1 = document.createElement('span')
    const flag = navigator.userAgent.match( // match方法可在字符串内检索指定的值，
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    )
    if (flag) {
        // 将foreignObject标签宽高和rect宽高相统一
        foreignObject.setAttribute('width', rectWidth*2.5)
        foreignObject.setAttribute('height', rectHeight)
    } else {
        // 将foreignObject标签宽高和rect宽高相统一
        foreignObject.setAttribute('width', rectWidth)
        foreignObject.setAttribute('height', rectHeight)
    }
    // 处理p标签
    p.style.margin = '10px 0 0 7px'
    p.style.textAlign = 'center'
    p.style.fontSize = '18px'
    p.style.height = rectHeight + 'px'
    p.style.lineHeight = rectHeight + 'px'
    p.style.display = 'flex'
    // p.style.justifyContent = 'space-around'
    p.style.alignItems = 'center'
    p.style.fontFamily = 'SourceHanSerifCN-Bold'
    // 处理span标签
    span1.style.color = v.attrs.label.fill ? v.attrs.label.fill : '#fff'
    span1.style.textShadow = '2px 2px 3px rgba(0,0,0,0.5)'
    span1.innerText = val?val:''

    // 将子元素追加至父元素
    p.appendChild(span1)
    body.appendChild(p)
    foreignObject.appendChild(body)
    return foreignObject
}